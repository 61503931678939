// Modules
import React, { useState } from "react";
import Head from "next/head";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Router from "next/router";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from "react-hot-toast";
import NProgress from "nprogress";
import dynamic from "next/dynamic";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/globals.scss";

// Utils
import { MediaContextProvider } from "../utils/mediaQueryHandler";

const CleverTap = dynamic(() => import("../components/CleverTap/CleverTap"), {
  ssr: false,
});

Sentry.init({
  // TBC - dsn key to be moved in .env when shifting to NextJS sentry
  dsn: "https://774b07ea29644bc1897a8d429ca5d3ff@sentry-op.fampay.in/6",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnWindowFocus: false, retry: false },
        },
      })
  );

  NProgress.configure({ showSpinner: false });
  Router.events.on("routeChangeStart", () => NProgress.start());
  Router.events.on("routeChangeComplete", () => NProgress.done());
  Router.events.on("routeChangeError", () => NProgress.done());

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=0, shrink-to-fit=no"
          />
          <title>FamApp: Make payments with your own UPI and Card</title>
        </Head>
        <MediaContextProvider disableDynamicMediaQueries>
          <CleverTap />
          <Component {...pageProps} isAnnouncement={true} />
          <ReactQueryDevtools initialIsOpen={false} />
          <Toaster />
        </MediaContextProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
